import React, {
  FunctionComponent,
  createContext,
  useEffect,
  useState,
} from "react";

interface Theme {
  background: string;
  primaryColor?: string;
  secondaryColor?: string;
  primaryWeight?: number;
}
export type StepType = "loading" | "tickets" | "form" | "payment" | "success";

type paymentType = "free";
interface PaymentMethod {
  type: paymentType;
  data: any; //link etc.
}

// from api
interface SubmissionResponse {
  payment: PaymentMethod;
  fee: number;
  id: string;
  responses: Record<string, SubmissionQuestion>;
}

// send to api
interface Submission {
  eventID: string;
  ticketType: string;
  responses: Record<string, SubmissionQuestion>;
}

interface SubmissionQuestion extends Question<QuestionDataTypes> {
  response: string;
}

export interface Question<QuestionTypeData> {
  id: string;
  required: boolean;
  type:
    | "shortText" //CommonQuestionData
    | "longText" //CommonQuestionData
    | "email" //CommonQuestionData
    | "department" //CommonQuestionData
    | "selection" //MultipleSelectionQuestionData
    | "phone" //CommonQuestionData
    | "file" //CommonQuestionData
    | "school" //CommonQuestionData
    | "checkbox" //CommonQuestionData
    | "info" //CommonQuestionData
    | "promotionCode"; //CommonQuestionData
  data: QuestionTypeData;
}

export interface CommonQuestionData {
  question: string;
}
export interface MultipleSelectionQuestionData {
  question: string;
  options: string[];
}

export type QuestionDataTypes =
  | CommonQuestionData
  | MultipleSelectionQuestionData;

interface ActiveTicket {
  id: string;
  label: string;
  fee: number;
  description: string;
  hideOnTicketList?: boolean;
  questions: Question<QuestionDataTypes>[];
}

interface Event {
  ActiveTickets: ActiveTicket[];
  eventID: string;
  eventName: string;
  theme: Theme;
  addToCalendarLink?: string;
}
interface ContextProps {
  event: Event;
  currentStep: StepType;
  selectedTicket: ActiveTicket;
  selectTicket: (ticket: ActiveTicket | undefined) => void;
  submitForm: (
    ticketID: string,
    formAnswers: Record<string, SubmissionQuestion>
  ) => Promise<void | { errorMessage: string }>;
  submissionResponse: SubmissionResponse;
}

const DatabaseContext = createContext<Partial<ContextProps>>({
  currentStep: "loading",
});

const DatabaseStore: FunctionComponent = ({ children }) => {
  const [event, setEvent] = useState<Event | undefined>();

  const [currentStep, setCurrentStep] = useState<StepType>("loading");

  const [selectedTicket, setselectedTicket] =
    useState<ActiveTicket | undefined>();

  const [submissionResponse, setsubmissionResponse] =
    useState<SubmissionResponse | undefined>();

  const localApi = "http://127.0.0.1:5001/integral-events/us-central1";
  const prodApi = "https://us-central1-integral-events.cloudfunctions.net";
  const [apiBase, setApiBase] = useState(
    process.env.NODE_ENV === "development" ? localApi : prodApi
  );

  const selectTicket = (ticket: ActiveTicket | undefined) => {
    setselectedTicket(ticket);
    setCurrentStep(ticket ? "form" : "tickets");
  };

  const changeApi = () => {
    setApiBase(apiBase === localApi ? prodApi : localApi);
  };

  const submitForm = async (
    ticketID: string,
    formAnswers: Record<string, SubmissionQuestion>
  ): Promise<void | { errorMessage: string }> => {
    let submission: Submission = {
      eventID: event!.eventID,
      ticketType: ticketID,
      responses: formAnswers,
    };

    return await fetch(`${apiBase}/v1-ets-api/submitForm`, {
      method: "POST",
      body: JSON.stringify(submission),
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
    })
      .then(async (r) => {
        if (r.status === 200) {
          return r.json();
        }
        throw new Error(await r.text());
      })
      .then((r: SubmissionResponse) => {
        // console.log(r);

        if (r.fee === 0) {
          setsubmissionResponse(r);

          setCurrentStep("success");
        } else {
        }
        // return r;
      });
  };

  useEffect(() => {
    fetch(`${apiBase}/v1-ets-api/getEventIDbyDomain`, {
      method: "POST",
      body: JSON.stringify({
        domain: window.location.host,
      }),
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
    })
      .then((r) => r.json())
      .then((r) => {
        setEvent(r);
        setCurrentStep("tickets");
      });
  }, [apiBase]);

  return (
    <DatabaseContext.Provider
      value={{
        event,
        currentStep,
        selectedTicket,
        selectTicket,
        submitForm,
        submissionResponse,
      }}
    >
      {process.env.NODE_ENV === "development" && (
        <button onClick={() => changeApi()}>env:{apiBase}</button>
      )}
      {children}
    </DatabaseContext.Provider>
  );
};

const useDatabase = () =>
  React.useContext<Partial<ContextProps>>(DatabaseContext);

export { DatabaseStore, useDatabase };
