import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Upload,
  message,
} from "antd";
import {
  MultipleSelectionQuestionData,
  Question,
  QuestionDataTypes,
  useDatabase,
} from "../core/DatabaseStore";
import React, { useMemo, useState } from "react";

import { AsYouType } from "libphonenumber-js";
import ReCAPTCHA from "react-google-recaptcha";
import { RuleObject } from "antd/lib/form";
import { Store } from "antd/lib/form/interface";
import { UploadOutlined } from "@ant-design/icons";
import { schoolDepartment } from "../assets/departments.json";
import { universities } from "../assets/universities.json";
import MultiLineLinkifyWrapper from "../components/MultiLineLinkifyWrapper";

const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
interface Props {}

const FormStep = (props: Props) => {
  const { event, selectedTicket, submitForm } = useDatabase();

  const [errorMessage, seterrorMessage] = useState("");

  const [submitting, setsubmitting] = useState(false);

  const onFinish = async (answers: Store) => {
    // handle file upload
    await Promise.all(
      selectedTicket!.questions
        .filter((q) => q.type === "file")
        .map(async (fileQuestion) => {
          const fqID = fileQuestion.id;
          if (Array.isArray(answers[fqID]) && answers[fqID][0]) {
            const file: File = answers[fqID][0]["originFileObj"];
            let s = await toBase64(file);

            answers[fqID] = `fileName:${file.name};${s}`;
          } else {
            answers[fqID] = "";
          }
        })
    );

    seterrorMessage("");
    setsubmitting(true);
    submitForm!(selectedTicket!.id, answers).catch((e: Error) => {
      console.log(e);
      recaptchaRef.current?.reset();

      message.error(e.message);
      seterrorMessage(e.message);
      setsubmitting(false);
    });
  };
  const validateMessages = {
    required: "Bu alan boş bırakılamaz.",
    types: {
      email: "Lütfen geçerli bir mail adresi girin.",
    },
  };

  const recaptchaRef = React.useRef<any>();

  return (
    <div>
      <Form
        // form={form}
        validateMessages={validateMessages}
        layout="vertical"
        // initialValues={{ requiredMark }}
        // onValuesChange={onRequiredTypeChange}
        // requiredMark={requiredMark}
        style={{ fontWeight: event?.theme.primaryWeight }}
        scrollToFirstError
        onFinish={(e: Store) => onFinish(e)}
      >
        <MultiLineLinkifyWrapper>
          {selectedTicket?.label}
        </MultiLineLinkifyWrapper>
        <MultiLineLinkifyWrapper>
          {selectedTicket?.description}
        </MultiLineLinkifyWrapper>

        <br />
        {selectedTicket?.questions.map((question, index) => {
          return (
            <>
              {question.type === "shortText" && (
                <ShortTextQuestion key={index} question={question} />
              )}
              {question.type === "selection" && (
                <SelectionQuestion key={index} question={question} />
              )}
              {question.type === "email" && (
                <EmailQuestion key={index} question={question} />
              )}
              {question.type === "checkbox" && (
                <CheckBoxQuestion key={index} question={question} />
              )}

              {question.type === "file" && (
                <FileUploadQuestion key={index} question={question} />
              )}
              {question.type === "info" && (
                <InfoQuestion key={index} question={question} />
              )}
              {question.type === "phone" && (
                <PhoneNumberQuestion key={index} question={question} />
              )}
              {question.type === "department" && (
                <CreatableSelectQuestion
                  key={index}
                  question={{
                    ...question,
                    data: {
                      ...question.data,
                      options: schoolDepartment,
                    },
                  }}
                />
              )}
              {question.type === "school" && (
                <CreatableSelectQuestion
                  key={index}
                  question={{
                    ...question,
                    data: {
                      ...question.data,
                      options: universities,
                    },
                  }}
                />
              )}
              {question.type === "longText" && (
                <LongTextQuestion key={index} question={question} />
              )}

              {question.type === "promotionCode" && (
                <ShortTextQuestion key={index} question={question} />
              )}
            </>
          );
        })}
        <Form.Item
          name="captcha"
          required
          rules={[
            {
              required: true,
              message: "Robot olmadığınızı doğrulayınız",
            },
          ]}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Ld_VGYaAAAAABh2BWKS885dmW3wcpLjlgJjXWa9"
          />
        </Form.Item>
        {errorMessage && (
          <Form.Item>
            <Alert
              message="Hata"
              description={errorMessage}
              type="error"
              showIcon
            />
          </Form.Item>
        )}
        <Form.Item>
          <Button loading={submitting} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

interface QuestionProps {
  question: Question<QuestionDataTypes>;
}

const CheckBoxQuestion = ({ question }: QuestionProps) => {
  const [checked, setChecked] = useState(false);
  const onCheckboxChange = async (e: any) => {
    await setChecked(e.target.checked);
  };
  const validation = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ) => {
    if (checked || !question.required) {
      return callback();
    }
    return callback("Please accept the terms and conditions");
  };
  return (
    <Form.Item
      name={question.id}
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      required={question.required}
      tooltip=""
      rules={[{ validator: validation }]}
      valuePropName="checked"
    >
      <Checkbox />
    </Form.Item>
  );
};
const ShortTextQuestion = ({ question }: QuestionProps) => {
  return (
    <Form.Item
      name={question.id}
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      required={question.required}
      tooltip="Tek satırlık kısa bir cevap giriniz."
      rules={[{ required: question.required }]}
    >
      <Input placeholder={question.data.question} />
    </Form.Item>
  );
};
const LongTextQuestion = ({ question }: QuestionProps) => {
  return (
    <Form.Item
      name={question.id}
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      required={question.required}
      tooltip="istediğiniz uzunlukta bir cevap giriniz."
      rules={[{ required: question.required }]}
    >
      <Input.TextArea autoSize placeholder={question.data.question} />
    </Form.Item>
  );
};
const EmailQuestion = ({ question }: QuestionProps) => {
  return (
    <Form.Item
      name={question.id}
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      required={question.required}
      tooltip="Kullanılan bir email adresi giriniz."
      rules={[{ required: question.required, type: "email" }]}
    >
      <Input placeholder={question.data.question} />
    </Form.Item>
  );
};

const SelectionQuestion = ({ question }: QuestionProps) => {
  let options = (question.data as MultipleSelectionQuestionData).options;
  return (
    <Form.Item
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      name={question.id}
      required={question.required}
      tooltip="Seçiniz."
      rules={[{ required: question.required }]}
    >
      <Select onKeyDown={(e) => {}}>
        {options?.map((item) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const turkishCharacterRegex = (keyword = "") =>
  keyword
    .replace(/[ıİiI]/g, "[ıİiI]")
    .replace(/[şŞsS]/g, "[şŞsS]")
    .replace(/[çÇcC]/g, "[çÇcC]")
    .replace(/[ğĞgG]/g, "[ğĞgG]")
    .replace(/[öÖoO]/g, "[öÖoO]")
    .replace(/[üÜuU]/g, "[üÜuU]");

const customFilter = (searchText: any, option: any) => {
  let opt = turkishCharacterRegex(option.value).toLowerCase();
  let search = turkishCharacterRegex(searchText).toLowerCase();
  return search.split(" ").every((subText) => opt.includes(subText));
};

const CreatableSelectQuestion = ({ question }: QuestionProps) => {
  let options = (question.data as MultipleSelectionQuestionData).options;

  const [newOption, setnewOption] = useState("");

  return (
    <Form.Item
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      name={question.id}
      required={question.required}
      tooltip="Seçiniz. Aradığınız seçeneği bulamadıysanız kendi seçeneğinizi oluşturabilirsiniz."
      rules={[{ required: question.required }]}
    >
      <Select
        onSearch={(e) => {
          setnewOption(e);
        }}
        onSelect={(e) => {
          options?.includes(e.toString()) && setnewOption("");
        }}
        showSearch
        onKeyDown={(e) => {}}
        filterOption={customFilter}
      >
        {options?.map((item) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
        {(!options || (newOption && !options?.includes(newOption))) && (
          <Select.Option
            key={"new: " + newOption}
            value={newOption}
            title={newOption}
          >
            {newOption}{" "}
            <Badge status="success" text="Yeni oluşturulan seçenek" />
          </Select.Option>
        )}
      </Select>
    </Form.Item>
  );
};
const FileUploadQuestion = ({ question }: QuestionProps) => {
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form.Item
      name={question.id}
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      required={question.required}
      tooltip="En fazla bir dosya yükleyiniz."
      rules={[{ required: question.required }]}
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >
      <Upload
        beforeUpload={(file) => {
          const isLt1M = file.size / 1024 / 1024 < 5;
          if (!isLt1M) {
            alert("file must smaller than 5 MB!");
          }
          return false;
        }}
        listType="picture"
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
};

const InfoQuestion = ({ question }: QuestionProps) => {
  return (
    <Form.Item>
      <MultiLineLinkifyWrapper>
        {question.data.question}
      </MultiLineLinkifyWrapper>
    </Form.Item>
  );
};

const PhoneNumberQuestion = ({ question }: QuestionProps) => {
  return (
    <Form.Item
      name={question.id}
      label={
        <MultiLineLinkifyWrapper>
          {question.data.question}
        </MultiLineLinkifyWrapper>
      }
      required={question.required}
      tooltip="Telefon numaranızı giriniz."
      rules={[{ required: question.required }]}
    >
      <PhoneNumberInput />
    </Form.Item>
  );
};

interface PhoneNumberInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  onChange,
}) => {
  const AYT = useMemo(() => new AsYouType("TR"), []);

  return (
    <Input
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        AYT.reset();
        AYT.input(val);

        let newVal = AYT.getNumber()?.number.toString() ?? val;
        onChange && onChange(newVal);
      }}
      style={{ width: "100%" }}
    />
  );
};

export default FormStep;
