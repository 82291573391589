import React from "react";
import Linkify from "react-linkify";

const MultiLineLinkifyWrapper: React.FC = ({ children }) => {
  return (
    // @ts-ignore
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target="blank"
          rel="noopener noreferrer"
          href={decoratedHref}
          key={key}
        >
          {decoratedText}
        </a>
      )}
    >
      <div
        style={{
          whiteSpace: "pre-line",
        }}
      >
        {children}
      </div>
    </Linkify>
  );
};

export default MultiLineLinkifyWrapper;
