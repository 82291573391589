import "./App.css";

import { DatabaseStore, useDatabase } from "./core/DatabaseStore";
import React, { useEffect, useRef, useState } from "react";

import { Card } from "antd";
import FormStep from "./sections/Form";
import IEFooter from "./components/IEFooter";
import Loading from "./components/Loading";
import Payment from "./sections/Payment";
import ProgressSteps from "./components/ProgressSteps";
import Success from "./sections/Success";
import Tickets from "./sections/Tickets";

function App() {
  const { event, currentStep, selectTicket } = useDatabase();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const myParam = urlParams.get("selectedTicketID");
    const selectedTicketID = (myParam && JSON.parse(myParam)) as string | null;

    selectTicket &&
      selectTicket(event?.ActiveTickets.find((t) => t.id === selectedTicketID));
  }, [event?.ActiveTickets]);

  return (
    <Card
      style={{
        backgroundColor: event?.theme.background || "transparent",
        border: "0",
        borderRadius: "2px",
      }}
    >
      {event ? (
        <>
          <div>
            <ProgressSteps />
            {currentStep === "tickets" && <Tickets />}
            {currentStep === "form" && <FormStep />}
            {currentStep === "payment" && <Payment />}
            {currentStep === "success" && <Success />}
          </div>
        </>
      ) : (
        <Loading />
      )}
      <IEFooter />
    </Card>
  );
}

export default App;
