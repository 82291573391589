import { Divider, Steps } from "antd";
import {
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { StepType, useDatabase } from "../core/DatabaseStore";

import React from "react";
import { isMobile } from "../core/services/device";

const { Step } = Steps;

interface Props {}

const ProgressSteps = (props: Props) => {
  const { event, currentStep, selectTicket } = useDatabase();

  const stepList = ["loading", "tickets", "form", "payment", "success"];

  const determineStatus = (screen: StepType) => {
    if (!currentStep) return "error";
    let val = stepList.indexOf(currentStep) - stepList.indexOf(screen);
    if (val > 0) {
      return "finish";
    } else if (val === 0) {
      return "process";
    } else {
      return "wait";
    }
  };
  return (
    <>
      <Steps style={{ fontWeight: event?.theme.primaryWeight, color: "red" }}>
        <Step
          status={determineStatus("tickets")}
          title={
            !isMobile() && (
              <span
                style={{
                  color:
                    determineStatus("tickets") === "wait"
                      ? "inherit"
                      : event?.theme.primaryColor,
                }}
              ></span>
            )
          }
          onClick={() => selectTicket && selectTicket(undefined)}
          style={{ cursor: "pointer" }}
          icon={
            <UserOutlined
              style={{
                color:
                  determineStatus("tickets") === "wait"
                    ? "inherit"
                    : event?.theme.primaryColor,
              }}
            />
          }
        />
        <Step
          status={determineStatus("form")}
          title={
            !isMobile() && (
              <span
                style={{
                  color:
                    determineStatus("form") === "wait"
                      ? "inherit"
                      : event?.theme.primaryColor,
                }}
              ></span>
            )
          }
          icon={
            <SolutionOutlined
              style={{
                color:
                  determineStatus("form") === "wait"
                    ? "inherit"
                    : event?.theme.primaryColor,
              }}
            />
          }
        />

        {event?.ActiveTickets.every((ticket) => {
          return ticket.fee !== 0;
        }) && (
          <Step
            status={determineStatus("payment")}
            title={
              !isMobile() && (
                <span
                  style={{
                    color:
                      determineStatus("payment") === "wait"
                        ? "inherit"
                        : event?.theme.primaryColor,
                  }}
                ></span>
              )
            }
            icon={
              <LoadingOutlined
                style={{
                  color:
                    determineStatus("payment") === "wait"
                      ? "inherit"
                      : event?.theme.primaryColor,
                }}
              />
            }
          />
        )}

        <Step
          status={determineStatus("success")}
          title={
            !isMobile() && (
              <span
                style={{
                  color:
                    determineStatus("success") === "wait"
                      ? "inherit"
                      : event?.theme.primaryColor,
                }}
              ></span>
            )
          }
          icon={
            <SmileOutlined
              style={{
                color:
                  determineStatus("success") === "wait"
                    ? "inherit"
                    : event?.theme.primaryColor,
              }}
            />
          }
        />
      </Steps>
      <Divider />
    </>
  );
};

export default ProgressSteps;
