import { Button, Descriptions, Divider, Result } from "antd";

import React from "react";
import { useDatabase } from "../core/DatabaseStore";


interface Props { }

const Success = (props: Props) => {
  const { submissionResponse, selectTicket, event } = useDatabase();
  if (!submissionResponse) {
    // to handle undefined response, this code will not be reached
    return <div>loading</div>;
  }
  return (
    <div>
      <Result
        status="success"
        title={submissionResponse.payment.data.successMessage}
        subTitle={submissionResponse.payment.data.successDetails}
        extra={[
          <>
            {event?.addToCalendarLink && (
              <a target="_blank" href={event?.addToCalendarLink}>
                <Button onClick={() => { window.open(event.addToCalendarLink) }} type="primary" key="console">
                  Takvime Ekle
                </Button>
              </a>
            )}
          </>,
          <Button key="buy" onClick={() => selectTicket!(undefined)}>
            Başka Bilet Al
          </Button>,
        ]}
      />
      <Divider />
      <Descriptions title="Kayıt Bilgileri" bordered>
        <Descriptions.Item
          labelStyle={{ width: "50%", backgroundColor: "#ffffff66" }}
          span={24}
          label={"Bilet Kodu"}
        >

          {submissionResponse.id}
        </Descriptions.Item>
        {Object.entries(submissionResponse.responses)?.map(
          ([qID, submissionQuestion], index) => {
            return (
              <Descriptions.Item
                labelStyle={{ width: "50%", backgroundColor: "#ffffff66" }}
                span={24}
                label={submissionQuestion.data.question}
              >
                {submissionQuestion.response &&
                  String(submissionQuestion.response)}
              </Descriptions.Item>
            );
          }
        )}
      </Descriptions>
    </div>
  );
};

export default Success;
