import React from "react";
import { Divider } from 'antd';
import { useDatabase } from "../core/DatabaseStore";

interface Props { }

const IEFooter = (props: Props) => {
  const {event} = useDatabase();
  return <div style={{
    textAlign: "end"
  }}>
    <Divider />
    <a
    style={{color: event?.theme.primaryColor}}
      href="https://integral.events"
      target="_blank"
      rel="noopener noreferrer"
    >
      Integral Event Solutions ©2021
</a></div>;
};

export default IEFooter;
