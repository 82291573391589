import { Card, Col, Row } from "antd";

import React, { useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import { useDatabase } from "../core/DatabaseStore";
import MultiLineLinkifyWrapper from "../components/MultiLineLinkifyWrapper";

interface Props {}

const Tickets = (props: Props) => {
  const { event, selectTicket } = useDatabase();

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("ticketIDs");
  const hiddenTickets = (myParam && JSON.parse(myParam)) as string[] | null;

  return (
    <div>
      <Row gutter={[16, 40]} justify="center">
        {event?.ActiveTickets.filter((t) => {
          if (t.hideOnTicketList) {
            if (hiddenTickets?.includes(t.id)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }).map((ticket, index) => (
          <Col xs={24} sm={12} md={8} xl={6}>
            <Card
              style={{
                maxWidth: "17rem",
                margin: "auto",
              }}
              onClick={() => {
                selectTicket && selectTicket(ticket);
              }}
              hoverable
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "4rem",
                    textAlign: "center",

                    whiteSpace: "break-spaces",
                    wordBreak: "break-word",

                    width: "100%",
                  }}
                >
                  {ticket.label}
                </div>
              }
              bordered={false}
              // extra={<>{ticket.fee === 0 ? "Ücretsiz" : ticket.fee}</>}
              actions={[
                <>{ticket.fee === 0 ? "Ücretsiz" : `${ticket.fee} TL`}</>,
                <RightOutlined
                  onClick={() => {
                    selectTicket && selectTicket(ticket);
                  }}
                  key="continue"
                />,
              ]}
            >
              <MultiLineLinkifyWrapper>
                <div
                  style={{
                    minHeight: "6rem",
                    textAlign: "center",

                    whiteSpace: "break-spaces",
                    wordBreak: "break-word",
                    overflow: "hidden",

                    width: "100%",
                  }}
                >
                  {ticket.description}
                </div>
              </MultiLineLinkifyWrapper>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Tickets;
